<template>
  <div class='login-container'>
    <CForm class='login-form' @submit='submitForm'>
      <h1 class='login-header'>super</h1>
      <CInput 
          v-model='username'
          placeholder='Username' 
          invalidFeedback='Username is invalid'
          :isValid='validateUsername'
          v-bind:class='{ valid: isValidUsername }'
          required>
          <template #prepend-content><CIcon name='cil-user'/></template>
        </CInput>
        <CInput 
          v-model='password'
          type='password' 
          placeholder='Password'
          invalidFeedback='Password is invalid'
          :isValid='validatePassword'
          v-bind:class='{ valid: isValidPassword }'
          required>
          <template #prepend-content><CIcon name='cil-shield-alt'/></template>
        </CInput>
      <div class='form-group form-actions'>
        <CButton block type='submit' color='primary' :disabled='formLoading'>
          <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
          Login
        </CButton>
      </div>
    </CForm>
  </div>
</template>

<script>
import apiAccount from '@/api/account'

export default {
  name: 'Login',
  data () {
    return { 
      username: null,
      password: null,
      redirect: null,
      formLoading: false,
      isValidUsername: true,
      isValidPassword: true
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods:{
    validateUsername (val) { // 4 to 16 alphanumeric OR email address
      if(val === null){
        this.isValidUsername = true
      }else{
        if(val && val.length >= 4 && val.length <= 16 && /^[A-Za-z0-9]+$/.test(val)){
          this.isValidUsername = true
          return true
        }else{
          this.isValidUsername = false
          return false
        }
      }
    },
    validatePassword (val) { // 6 to 16 characters
      if(val === null){
        this.isValidPassword = true
      }else{
        if(val && val.length >= 6 && val.length <= 16){
          this.isValidPassword = true
          return true
        }else{
          this.isValidPassword = false
          return false
        }
      }
    },
    submitForm: function (e) {
      
      e.preventDefault()

      if(this.username === null){ this.username = '' }
      if(this.password === null){ this.password = '' }

      if(this.validateUsername(this.username) && this.validatePassword(this.password)){
        
        this.formLoading = true

        apiAccount.login(this.username, this.password).then(response => {
          this.formLoading = false
          if (response.data.status === 'success') {

            window.localStorage.setItem('authToken', response.data.authToken)
            window.localStorage.setItem('userID', response.data.userID)
            window.localStorage.setItem('username', response.data.username)
            window.localStorage.setItem('isSuper', response.data.isSuper)

            this.$router.push({ path: this.redirect || '/' })

            this.$toast.success('Successfully logged in.', {
              position: 'top-right',
              timeout: 3000,
              hideProgressBar: true
            })

          } else {
            
            this.$toast.error('Login unsuccessful.', {
              position: 'top-right',
              timeout: 5000,
              hideProgressBar: true
            })

          }
        }).catch((err) => {
            this.formLoading = false
            this.$toast.error('Login unsuccessful.', {
              position: 'top-right',
              timeout: 5000,
              hideProgressBar: true
            })

        })

      }

    }
  }
}
</script>
